import './../assets/style/modal.css';
import './../assets/style/download.css';

function Download({ closeModal, buttonDownload }) {


    return (
        <form className='popup'>
            <div className='close' onClick={closeModal}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Close">
                        <path id="Vector" d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#2D264B" strokeWidth="1.5" strokeLinecap="round" />
                    </g>
                </svg>
            </div>
            
            <a href={buttonDownload} download target="_blank" className='butt'>Download Application Form</a>
        </form>
    );
}

export default Download;