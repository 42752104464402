import React, { useState } from 'react';
import { useEffect } from 'react';
import Header from "./../components/header";
import WardRow from "./../components/WardRow";
import './../assets/style/dashboard.css';
import { getAllWards, getAllAssemblies } from '../api/services';
import Loader from '../components/loader';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppModal from "../components/AppModal";
import AddWard from "../components/AddWard";
import ButtonIcon from "../components/ButtonIcon";
import NoRecord from "../components/NoRecord";
import { Grid } from "@mui/material";
import Sidebar from "./../components/Sidebar";

const Ward = () => {
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    // Ward List & Admin List
    const [ward, setWard] = useState([]);
    const [assembly, setAssembly] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const wardInit = () => {
        getAllWards().then(
            response => {
                if (response.success) {
                    setWard(response.data.ward);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };
    const assembliesInit = () => {
        getAllAssemblies().then(
            response => {
                if (response.success) {
                    setAssembly(response.data.assemblies);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };

    useEffect(() => {
        if (window.localStorage.getItem("login")) {
            wardInit();
            assembliesInit();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        else{
            navigate("/")
        }

    }, [])

    const [popupWard, setPopupWard] = useState(false);
    const showPopupWard = () => {
        setPopupWard(true)
    };
    const closePopupWard = () => {
        setPopupWard(false)
    }
    return (
        <>
        <Header />
        <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={0} md={2.3} xl={1.7}>
                <Sidebar />
            </Grid>
            <Grid item xs={12} md={9.7} xl={10.3}>
                <div className='dashboard'>
                        <ToastContainer />
                    <AppModal
                            isOpen={popupWard}
                            content={<AddWard initFunc={wardInit} closeModal={closePopupWard} assemblies={assembly} />}
                    />
                    {isLoading ? (
                            <div> <Loader /> </div>
                        ) : (
                        <div className='in'>
                            <div className='wardHead'>
                                <p>Ward</p>
                                { userData.role === 'superadmin' &&
                                <button onClick={showPopupWard}>
                                    <ButtonIcon />
                                    Create ward
                                </button>
                                }
                            </div>
                            <div className='wardList'>
                                {ward.length === 0 ?
                                    <NoRecord />:
                                ward && ward.map((data, index) => (
                                    <WardRow ward={data} index={index} />
                                ))}
                            </div>
                        </div>
                        )
                    }
                </div>
            </Grid>
        </Grid>        
      </>        
    )
}

export default Ward;