import React, {useState} from 'react';
import ButtonLoader from "./ButtonLoader";
import './../assets/style/modal.css';
import {toast} from 'react-toastify';
import axios from 'axios';

function AdsDocumentUpload({ dataid, closeModal, reLoad }) {

    const [isButtonLoading, setButtonIsLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [changeStatus, setChangeStatus] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [selectedFile, setSelectedFile] = useState([]);
    //const [assignUser, setAssignUser] = useState("");

    const fileUploadError = {
        documentName: "Document name is required.",
        selectedFile: "File is required.",
        fileSize: "File size cannot exceed more than 1MB",
        //assignUser: "Please select user",
    };
    
    function handleChange(event) {
        //console.log(event.target.files[0]);
        //return false;
        setSelectedFile(event.target.files[0]); 
    }

    const handleUploadDocument = async(e) => {
        e.preventDefault();
        //console.log(selectedFile);
        if (!documentName) {
            setErrorMessages({ name: "name_error", message: fileUploadError.documentName });
            return;
        }

        if (!selectedFile) {
            setErrorMessages({ name: "file_error", message: fileUploadError.selectedFile });
            return;
        }

        // const file = e.target.files[0];
        // if (file.size > 1024){
        //     setErrorMessages({ name: "file_error", message: fileUploadError.fileSize });
        //     return;
        // }

        if (!window.localStorage.getItem("login")) {
            return { 'success': false, 'message': 'Invalid user' };
        }
        const formDataToSend = new FormData();
        let userData = JSON.parse(window.localStorage.getItem("userData"));
        formDataToSend.append('user_id', userData.id);
        formDataToSend.append('token', userData.token);
        formDataToSend.append('role', userData.role);
        formDataToSend.append('advertisement_id', dataid);
        formDataToSend.append('document[0][document]', selectedFile);
        formDataToSend.append('document[0][title]', documentName);

        try {
            const response = await axios.post('http://35.154.214.0/devproject/public/api/web/advertisement/document_upload', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            let apiResponse = response.data;
            if (apiResponse.success) {
                resetStates();
                closeModal();
                reLoad();
                toast.success(apiResponse.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                //alert(JSON.stringify(apiResponse));
            } else {
                toast.error(apiResponse.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        
    };
    // Render error messages
    const renderErrorMsg = (name) =>
        name === errorMessages.name && (
            <span className="error">{errorMessages.message}</span>
        );

        //console.log(dataid);

    let resetStates = () => {
        setDocumentName([]);
        setSelectedFile([]);
    }
    return (
        
        <form className='popup' onSubmit={handleUploadDocument}>
            <div className='close' onClick={closeModal}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Close">
                        <path id="Vector" d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#2D264B" strokeWidth="1.5" strokeLinecap="round" />
                    </g>
                </svg>
            </div>
            <div className='input'>
                <input type='text' placeholder='First name' value={documentName} onChange={(event) => { setDocumentName(event.target.value)}} />
                <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Profile 1">
                        <g id="Vector">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8 0.25C5.37665 0.25 3.25 2.37665 3.25 5C3.25 7.62335 5.37665 9.75 8 9.75C10.6234 9.75 12.75 7.62335 12.75 5C12.75 2.37665 10.6234 0.25 8 0.25ZM4.75 5C4.75 3.20507 6.20507 1.75 8 1.75C9.79493 1.75 11.25 3.20507 11.25 5C11.25 6.79493 9.79493 8.25 8 8.25C6.20507 8.25 4.75 6.79493 4.75 5Z" fill="#2D264B" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M5 11.25C2.37665 11.25 0.25 13.3766 0.25 16C0.25 18.6234 2.37665 20.75 5 20.75H11C13.6234 20.75 15.75 18.6234 15.75 16C15.75 13.3766 13.6234 11.25 11 11.25H5ZM1.75 16C1.75 14.2051 3.20507 12.75 5 12.75H11C12.7949 12.75 14.25 14.2051 14.25 16C14.25 17.7949 12.7949 19.25 11 19.25H5C3.20507 19.25 1.75 17.7949 1.75 16Z" fill="#2D264B" />
                        </g>
                    </g>
                </svg>
            </div>
            {renderErrorMsg("name_error")}
            <div className='input'>
                <input type="file" onChange={handleChange} accept=".pdf, .jpg, .png"/>
            </div>
            {renderErrorMsg("file_error")}
            <button disabled={isButtonLoading}>{isButtonLoading ? <ButtonLoader /> : 'Upload Document'}</button>
        </form>
    );
}

export default AdsDocumentUpload;