import React, { useState } from 'react';
import { useEffect } from 'react';
import './../assets/style/header.css';
import Badge from '@mui/material/Badge';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    const [menu, setMenu] = useState("headerAccountSec");
    const navigate = useNavigate();
    const showNav = () => {
        setMenu('headerAccountSec activeNavbar')
    };
    const closeNav = () => {
        setMenu('headerAccountSec')
    }
    const logout = () => {
        window.localStorage.clear();
        navigate("/");
    }
    return (
        <div className='header'>
            <div className={menu}>
              
                <div className='menu'></div>


                <div className='NA'>
                    <div className='notification'>
                        {/* <Badge sx={{ cursor: "pointer" }} color="primary" badgeContent={9}>
                            <NotificationsNoneOutlinedIcon />
                        </Badge> */}
                    </div>
                    <div className='logout'>
                        <LogoutIcon onClick={logout} sx={{ cursor: "pointer" }} />
                    </div>
                    <div className='account'>
                        <Avatar sx={{ height: { xs: '40px', sm: '50px', md: "50px" }, width: { xs: '40px', sm: '50px', md: "50px" }, cursor: "pointer" }}>R</Avatar>
                        <div className='AD'>
                            <p>
                                {userData.first_name ?? ''+' '+userData.last_name ?? ''}
                            </p>
                            <small>
                                {userData.email ?? ''}
                            </small>
                        </div>
                    </div>

                </div>
                <div onClick={closeNav} className='closeNavbar'>
                    <CloseOutlinedIcon className='icon' />

                </div>
            </div>


            <div onClick={showNav} className='toddleNavbar'>
                <MenuOutlinedIcon className="icon" />
            </div>
        </div>
    )
}

export default Header
