import React from 'react'
import './../assets/style/loader.css'
function Loader() {
    return (
        <div
            id="spinner"
        >
            <div
                style={{ width: "100%", height: "90%" }}
                role="status"
            >
                <div className="loader" id="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export default Loader
