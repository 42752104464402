import React from 'react';
import Modal from 'react-modal';

function AppModal({ isOpen, content, top='100' }) {
    return (
        <Modal
            isOpen={isOpen} className='modal-sm'>
            <div className='cusmodal' style={{top:top+'px'}}>
                {content}
            </div>
        </Modal>
    );
}

export default AppModal;