import React from 'react';
import {deleteAdvertisementDocument} from "../api/services";
import {toast} from "react-toastify";

function DocumentListPopup({documents, onClose, advertiseId}) {

    const deleteDocument = (adId, docId) => {
        deleteAdvertisementDocument(adId, docId).then(
            response => {
                onClose();
                if (response.success) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };

    return (
        <div className='popup'>
            <h2>Uploaded documents</h2>
            <ul>
                {
                    documents && documents.map((item) => (
                        <li>
                            <a title={item.title} target='_blank' href={item.document} download>{item.title}</a>
                            <span style={{color: "red", fontSize: "24px", cursor: "pointer",}} onClick={()=> deleteDocument(advertiseId, item.id)}>&times;</span>
                        </li>
                    ))
                }
            </ul>
            <button onClick={onClose}>Close</button>
        </div>
    );
}

export default DocumentListPopup;