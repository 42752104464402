import React, {useState} from 'react';
import '../assets/style/adslist.css';

function VerifiedAdRow({ addata }) {
    const [modalVisible, setModalVisible] = useState(false);
    const handleImageClick = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    return (
        <div>
            {addata.location_detail_id && <div className="card">

                <div className="corner-tag">NEW</div>
                { addata.location_detail_id && <img src={addata.image} alt='' className="card-image" onClick={handleImageClick}/>}
                {modalVisible && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content">
                            <img src={addata.image} alt="Zoomed Image" className="zoomed-image" />
                        </div>
                    </div>
                )}
                <div className='details'>
                    <p className='adcode'><small>Ad Code : </small>{addata.advertisement_code}</p>
                    <p><small>Ward no : </small>{addata.ward_name}</p>
                    <p><small>Address : </small>{addata.location}</p>
                    <p><small>User Verified Address : </small>{addata.address}</p>
                    <p><small>Latitude : </small>{addata.latitude}</p>
                    <p><small>Longitude : </small>{addata.longitude}</p>
                </div>
            </div>}
        </div>
    );
}

export default VerifiedAdRow;