const Data2 = [
    {
        id: 1,
        img: "https://5.imimg.com/data5/SS/CN/MY-34687665/road-side-advertising-board-1000x1000.jpg",
        wardNo: 4,
        address: "Garui budha mandir",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        latitude: "0011.0",
        longitude: "025445.21",
        active:true
    },
    {
        id: 2,
        img: "https://c8.alamy.com/comp/ERYKA4/roadside-banner-depicting-high-lifestyle-of-affluent-mahalaxmi-bombay-ERYKA4.jpg",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        wardNo: 4,
        address: "Garui budha mandir",
        latitude: "0011.0",
        longitude: "025445.21",
        active:false
    },
    {
        id: 3,
        img: "https://www.shutterstock.com/shutterstock/photos/2172817273/display_1500/stock-photo-bengaluru-karnataka-india-june-hoarding-of-state-bank-of-india-by-the-roadside-2172817273.jpg",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        wardNo: 4,
        address: "Garui budha mandir",
        latitude: "0011.0",
        longitude: "025445.21",
        active:true
    },
    {
        id: 4,
        img: "https://5.imimg.com/data5/SELLER/Default/2022/6/AZ/YC/BP/19443194/display-hoarding-advertising-service.jpg",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        wardNo: 4,
        address: "Garui budha mandir",
        latitude: "0011.0",
        longitude: "025445.21",
        active:false
    },
    {
        id: 5,
        img: "https://c8.alamy.com/comp/FCFTP9/pedestrians-walk-past-a-roadside-billboard-advertising-scooters-in-FCFTP9.jpg",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        wardNo: 4,
        address: "Garui budha mandir",
        latitude: "0011.0",
        longitude: "025445.21",
        active:true
    },
    {
        id: 6,
        img: "https://content.jdmagicbox.com/rep/b2b/hoardings/hoardings-6.jpg?impolicy=queryparam&im=Resize=(360,360),aspect=fit",
        AssemblyCode: "JHG/SDDM/2023/08/12/1334563",
        wardNo: 4,
        address: "Garui budha mandir",
        latitude: "0011.0",
        longitude: "025445.21",
        active:false
    }
]

export default Data2;