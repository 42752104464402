import React, {useEffect} from 'react';
import Header from "./../components/header";
import AdsRegister from "../components/AdsRegister";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/style/AddAdvertisement.css';
import {Grid} from "@mui/material";
import Sidebar from "./../components/Sidebar";

const RegisterAds = () => {

    return (
        <>
            <Header />
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={0} md={2.3} xl={1.7}>
                    <Sidebar />
                </Grid>
                <Grid item xs={12} md={9.7} xl={10.3}>
                    <div className='advertisement'>
                        <ToastContainer />
                        <div className='in'>
                            <div className='title'>
                                <p>Add advertisement</p>
                            </div>
                            <AdsRegister />
                        </div>
                    </div >
                </Grid>
            </Grid>

        </>
    )

}

export default RegisterAds;